import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { BugoBasicButton } from '@service/bugo/components/BugoBasicButton';
import { useNavigate } from 'react-router-dom';
import PreedLogo from 'src/assets/logo/preedTop.png';

import { ReactComponent as GoogleLogo } from '/src/assets/svg/GoogleLogo.svg';
import { ReactComponent as KaKaoLogo } from '/src/assets/svg/kakao-svgrepo-com.svg';

import { LoginForm } from '@shared/components/LoginForm';

//! pseudocode
/**
 * @components
 * @shared
 *! - Input(TextField)
 *! - Button
 * @structure
 *! - Logo
 *! - Title
 *! - SubmitForm
 *! |- InputSet
 *! ||- Id
 *! ||- Password
 *! |- ButtonSet
 *! ||- PersistCheckBox
 *! ||- SubmitButton
 *! ||- FindIdButton
 *! ||- SignupButton
 */

const SellerLoginPage: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  //* 로그인후 가야할 url
  const redirectUrlAfterSellerLogin = sessionStorage.getItem(
    'redirectUrlAfterSellerLogin',
  );

  //TODO: test용도, 나중에 지워야 함
  // const [theme, setTheme] = useRecoilState(customThemeAtom);

  // const themeToggle = useCallback(() => {
  //   switch (theme) {
  //     case UserCustomTheme.Default:
  //       setTheme(UserCustomTheme.Default);
  //       break;
  //     case UserCustomTheme.Dark:
  //       setTheme(UserCustomTheme.Dark);
  //       break;
  //     case UserCustomTheme.Preed:
  //       setTheme(UserCustomTheme.Preed);
  //       break;
  //     default:
  //       setTheme(UserCustomTheme.Dark);
  //   }
  // }, [theme, setTheme]);

  //TODO: 여기까지
  return (
    <div className="pt-10">
      {/* Logo */}
      <div className="grid place-items-center">
        <img src={PreedLogo} className="h-[60px]"></img>
      </div>
      {/* Title */}
      <div className="flex w-full justify-center">
        <div className="mx-6 mt-8 w-full max-w-md">
          <div className="py-10 px-3 pb-8 shadow-smd theme-bg-JM05">
            <div>
              {/* SubmitForm */}
              <LoginForm redirectUrl={redirectUrlAfterSellerLogin ?? '/flower-seller'} />
              <div className="inline-flex w-full items-center justify-center">
                <hr className="my-6 h-px w-full border-0 bg-[#D1D5DB]" />
                <span className="absolute left-1/2 -translate-x-1/2 px-3 text-12 theme-text-8 theme-bg-JM05">
                  회원이 아니세요?
                </span>
              </div>
              {/* SignupButton */}
              <BugoBasicButton
                onClick={() => {
                  navigate('/signup');
                }}
                className="rounded-lg"
                colorTheme="default"
                text="회원가입"
              />
              <div className="inline-flex w-full items-center justify-center">
                <hr className="my-6 h-px w-full border-0 bg-[#D1D5DB]" />
                <span className="absolute left-1/2 -translate-x-1/2 px-3 text-12 theme-text-8 theme-bg-JM05">
                  간편로그인
                </span>
              </div>
              <BugoBasicButton
                onClick={() =>
                  loginWithRedirect({ redirectUri: `${window.location.origin}` })
                }
                className="relative rounded-lg"
                colorTheme="default"
              >
                <GoogleLogo className="absolute left-3.5 h-5 w-5" />
                <KaKaoLogo className="absolute left-10 h-5 w-5 text-kakao-brown" />
                {window.innerWidth > 320 ? (
                  <span>간편로그인으로 시작하기</span>
                ) : (
                  <span>간편로그인</span>
                )}
              </BugoBasicButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SellerLoginPage };
