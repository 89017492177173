import { FC } from 'react';

import { IconFormer } from '@shared/components/IconsFormer';
import { Icon } from '@shared/components/icons';

export const CustomerCallCenterBox: FC = function CustomerCenterBox() {
  const { CustomerCenter } = Icon();
  return (
    <div className="border-b border-t bg-white p-4 py-1">
      <div className="center-box gap-4 font-bold">
        <div className="center-box flex-col items-center">
          <CustomerCenter />
          <p className="">전화주문 및 안내</p>
          <p className="theme-text-main">오전8시 - 오후8시</p>
        </div>
        <a href={`tel:18006987`}>
          <div className=" center-box gap-1 text-18">
            <IconFormer.Phone className="wh-4 fill-gray-700" />
            <p>1800-6987</p>
          </div>
        </a>
      </div>
    </div>
  );
};
