import React, { useCallback, useEffect, useMemo } from 'react';

import { useRecoilState, useSetRecoilState } from 'recoil';

import { signupValidAtom } from '@service/bugo/containers/SignupAgreementForm/state/SignupValidate.atom';
import { checkBoxStatusAtomFamily } from '@shared/containers/StatefulCheckbox/state/CheckBox.atom';
import { StatefulCheckBox } from '@shared/containers/StatefulCheckbox/StatefulCheckBox';
import { useLocation } from 'react-router-dom';
import './index.css';
//! psesudocode
/**
 * @components
 *  - Precautions
 */

type SignupAgreementFormProps = {
  purpose: 'signup' | 'upgrade';
};

const guideTitle1 = '먼저 이용약관을 동의해주세요.';
const guideTitle2 = '해당하는 회원유형을 선택해주세요.';
const precautions = (
  <span>
    고객은 동의를 거부할 권리가 있으며 동의를 거부할 경우,
    <br />
    사이트가입 또는 일부 서비스 이용이 제한됩니다.
    <br />
    가입은 만 14세 이상 고객만 가능합니다.
  </span>
);

const SignupAgreementForm: React.FC<SignupAgreementFormProps> = (props) => {
  const { purpose } = props;

  // Agreement State
  const [allAgreeState, setAllAgreeState] = useRecoilState(
    checkBoxStatusAtomFamily(`${purpose}-allAgree`),
  );
  const [termsAgreeState, setTermsAgreeState] = useRecoilState(
    checkBoxStatusAtomFamily(`${purpose}-termsAgree`),
  );
  const [privacyAgreeState, setPrivacyAgreeState] = useRecoilState(
    checkBoxStatusAtomFamily(`${purpose}-privacyAgree`),
  );

  // User role State
  const [agencyRoleState, setAgencyRoleState] = useRecoilState(
    checkBoxStatusAtomFamily(`${purpose}-agencyRole`),
  );
  const [funeralRoleState, setFuneralRoleState] = useRecoilState(
    checkBoxStatusAtomFamily(`${purpose}-funeralRole`),
  );
  const [normalRoleState, setNormalRoleState] = useRecoilState(
    checkBoxStatusAtomFamily(`${purpose}-normalRole`),
  );

  // Validate setter
  const setValid = useSetRecoilState(signupValidAtom);

  const location = useLocation();

  // initialization
  useEffect(() => {
    return () => {
      setAllAgreeState(false);
      setTermsAgreeState(false);
      setPrivacyAgreeState(false);
      setAgencyRoleState(false);
      setFuneralRoleState(false);
      setNormalRoleState(false);
    };
  }, [location]);

  // 전체(allAgreeState) 선택시 동의 2항목(termsAgreeState, privacyAgreeState) true 적용
  const onAllAgreeClick = useCallback(() => {
    setTermsAgreeState(!allAgreeState);
    setPrivacyAgreeState(!allAgreeState);
  }, [allAgreeState, setTermsAgreeState, setPrivacyAgreeState]);

  // 동의 2항목(termsAgreeState, privacyAgreeState) condition에 따라 전체(allAgreeState) 상태 변환
  useEffect(() => {
    const allAgree = termsAgreeState && privacyAgreeState;
    setAllAgreeState(allAgree);
  }, [termsAgreeState, privacyAgreeState, setAllAgreeState]);

  // 회원유형 3가지 (agencyRoleState, funeralRoleState, normalRoleState) Radio 기능 구현
  useEffect(() => {
    if (agencyRoleState) {
      setFuneralRoleState(false);
      setNormalRoleState(false);
    }
  }, [agencyRoleState, setFuneralRoleState, setNormalRoleState]);
  useEffect(() => {
    if (funeralRoleState) {
      setAgencyRoleState(false);
      setNormalRoleState(false);
    }
  }, [funeralRoleState, setAgencyRoleState, setNormalRoleState]);
  useEffect(() => {
    if (normalRoleState) {
      setAgencyRoleState(false);
      setFuneralRoleState(false);
    }
  }, [normalRoleState, setAgencyRoleState, setFuneralRoleState]);

  // 회원유형 선택 유무에 따른 state
  const isRoleSelected = useMemo(() => {
    return agencyRoleState || funeralRoleState || normalRoleState;
  }, [agencyRoleState, funeralRoleState, normalRoleState]);

  // validate for button disable state
  useEffect(() => {
    setValid(allAgreeState && isRoleSelected);
  }, [allAgreeState, isRoleSelected, setValid]);

  return (
    <div className="w-full pl-4 pr-4 pt-6 text-14 font-bold">
      <div className="pl-2">
        <span>{guideTitle1}</span>
      </div>
      <div className="mt-3 mb-3 w-full pt-2 pb-2 theme-bg-1">
        <label className="relative h-11 w-full">
          <span className="absolute left-4 top-2 leading-7">전체동의</span>
          <StatefulCheckBox
            onClick={onAllAgreeClick}
            className="absolute top-3 right-6"
            atomName={`${purpose}-allAgree`}
          />
        </label>
        <div className="w-full pl-4 pr-4">
          <div className="h-[1px] w-full bg-gray-300" />
        </div>
        <label className="relative mt-1 h-11 w-full theme-text-8">
          <span className="absolute left-6 top-2 leading-7">
            스마트나누미 이용약관 동의 <span className="text-12 font-medium">(필수)</span>
          </span>
          <StatefulCheckBox
            className="absolute top-3 right-6"
            atomName={`${purpose}-termsAgree`}
          />
        </label>
        <label className="relative mt-1 h-11 w-full theme-text-8">
          <span className="absolute left-6 top-2 leading-7">
            개인정보 처리방침 동의 <span className="text-12 font-medium">(필수)</span>
          </span>
          <StatefulCheckBox
            className="absolute top-3 right-6"
            atomName={`${purpose}-privacyAgree`}
          />
        </label>
      </div>
      <div className="pl-5 pr-5 text-12 font-light leading-[18px] theme-text-8">
        {precautions}
      </div>
      {/* user role selector */}
      <div className="mt-8 pl-2">
        <span>{guideTitle2}</span>
      </div>
      <div className="mt-3 mb-3 w-full pt-2 pb-2 theme-bg-1">
        <label className="relative mt-1 h-11 w-full theme-text-8">
          <span className="absolute left-6 top-2 leading-7">상조회사</span>
          <StatefulCheckBox
            className="absolute top-3 right-6"
            atomName={`${purpose}-agencyRole`}
          />
        </label>
        <label className="relative mt-1 h-11 w-full theme-text-8">
          <span className="absolute left-6 top-2 leading-7">장례식장</span>
          <StatefulCheckBox
            className="absolute top-3 right-6"
            atomName={`${purpose}-funeralRole`}
          />
        </label>
        <label className="relative mt-1 h-11 w-full theme-text-8">
          <span className="absolute left-6 top-2 leading-7">
            일반회원 <span className="text-12 font-medium">(상주, 모임, 단체 등)</span>
          </span>
          <StatefulCheckBox
            className="absolute top-3 right-6"
            atomName={`${purpose}-normalRole`}
          />
        </label>
      </div>
    </div>
  );
};

export { SignupAgreementForm };
