import { useEffect } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { AdminRouter } from '@route/AdminRouter';
import { BugoRouter } from '@route/BugoRouters/BugoRouter';
import { SellerRouter } from '@route/SellerRouters/SellerRouter';
import { StoreRouter } from '@route/StoreRouters/StoreRouter';
import { ScrollToTop } from '@shared/components/ScrollToTop';
import { useCssVariables } from '@shared/hooks/useCssVariables';
import useCustomThemeHook from '@shared/hooks/useCustomThemeHook';
import { KakaoCallbackPage } from '@shared/page/KakaoCallbackPage';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { mainColorGenerator } from '@shared/utils/mainColorGenerator';
import { notification } from 'antd';
import { Route, Routes, useLocation } from 'react-router-dom';

function App() {
  useCustomThemeHook();
  const customTheme = useRecoilValue(customThemeAtom);
  const useGrayBgColor = false;
  const { pathname } = useLocation();
  const [api, contextHolder] = notification.useNotification();
  const setNotificationInstance = useSetRecoilState(notificationInstanceAtom);

  useCssVariables([
    {
      property: '--main-color',
      value: mainColorGenerator(customTheme),
    },
  ]);

  useEffect(() => {
    setNotificationInstance(api);
  }, [api, setNotificationInstance]);

  return (
    <>
      {contextHolder}
      <div>
        <ScrollToTop />
        <div className={`bg-white ${customTheme}`}>
          <div
            className={`mx-auto min-h-screen w-full ${
              pathname.endsWith('-stat') || pathname.includes('flower-seller')
                ? ''
                : 'max-w-screen-md'
            }  theme-text-1 ${!useGrayBgColor ? 'theme-bg-6' : 'theme-bg-7'}`}
          >
            <Routes>
              <Route path="*" element={<BugoRouter />} />
              <Route path="flower-store/*" element={<StoreRouter />} />
              <Route path="flower-seller/*" element={<SellerRouter />} />
              <Route path="admin/*" element={<AdminRouter />} />
              <Route path="social/kakao/auth" element={<KakaoCallbackPage />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
