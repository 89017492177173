import { FC, useEffect } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import { SidePopup } from '@shared/components/SidePopup';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DaemyungLogo from 'src/assets/logo/daemyungTop.png';

import {
  storeNavBackUrlAtom,
  storeNavBarTitleAtom,
  storeNavBarUseAtom,
  storeNavRightTextAtom,
} from './storeNavBar.atom';

export const StoreNavBar: FC = function StoreNavBar() {
  const { User, ChevronRight, EmptyUser, Back, Menu } = Icon();
  const openId = 'storeNavSidePopup';
  const setSideMenuOpen = useSetRecoilState(simpleOpenAtomFamily(openId));
  const location = useLocation();
  const pathName = location.pathname;
  const navigate = useNavigate();
  const title = useRecoilValue(storeNavBarTitleAtom);
  const rightText = useRecoilValue(storeNavRightTextAtom);
  const navBarUse = useRecoilValue(storeNavBarUseAtom);
  const backUrl = useRecoilValue(storeNavBackUrlAtom);

  const { userProfile, logout, guestAuthenticated } = useAuth();

  useEffect(() => {
    return setSideMenuOpen(false);
  }, [setSideMenuOpen]);

  const menuButton = (text: string, to: string) => {
    return (
      <Link
        to={to}
        className="flex h-10 w-full items-center justify-between pl-1"
        onClick={() => {
          setSideMenuOpen(false);
        }}
      >
        <p className="bugo-h2">{text}</p>
        <ChevronRight />
      </Link>
    );
  };

  const storeSideMenu = (login: boolean) => {
    const sharedMenu = (
      <>
        <div>
          <div className="px-4 py-0.5">
            <p className="text-xs font-bold leading-5 theme-text-8">주문배송</p>
          </div>
          <div className="space-y-2 px-3 py-2 theme-bg-1">
            {menuButton('주문내역', '/flower-store/order')}
            {menuButton('배송완료내역', '/flower-store/order/?status=Delivered')}
          </div>
        </div>
        <div>
          <div className="px-4 py-0.5">
            <p className="text-xs font-bold leading-5 theme-text-8">고객센터</p>
          </div>
          <div className="space-y-2 px-3 py-2 theme-bg-1">
            {menuButton('자주 묻는 질문', '/flower-store/faq')}
            {menuButton('문의하기', '/flower-store/enquiry')}
          </div>
        </div>
      </>
    );

    if (login) {
      return (
        <div className="relative min-h-screen w-64 theme-text-1 theme-bg-6">
          <div className="absolute right-0 top-0"></div>
          <div className="flex items-center justify-between pl-3 pr-2 pt-10 pb-4 theme-bg-1">
            <div className="flex items-center gap-2">
              {/* onClick 함수 없어질 예정 for test */}
              <User />
              <p className="bugo-h1">{userProfile?.info?.name}님</p>
            </div>
            {/* TODO: Theme에 맞는 아이콘으로 변경. */}
            <ChevronRight />
          </div>
          <div>
            <div className="px-4 py-0.5">
              <p className="text-xs font-bold leading-5 theme-text-8">마이페이지</p>
            </div>
            <div className="space-y-2 px-3 py-2 theme-bg-1">
              {menuButton('적립금 관리', '/fevent/add')}
              {menuButton('나를 추천한 회원', '/fevent/manage')}
            </div>
          </div>
          {sharedMenu}
          <div className="flex items-center justify-end p-4">
            <Button
              className="button-fold text-xs leading-5 theme-text-8 theme-bg-1 theme-border-1"
              onClick={() => {
                logout();
              }}
            >
              로그아웃
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="min-h-screen w-64 theme-text-1 theme-bg-6">
          <Link to="/flower-store/login">
            <div className="flex items-center justify-between pl-3 pr-2 pt-10 pb-4 theme-bg-1">
              <div className="flex items-center gap-2">
                {/* onClick 함수 없어질 예정 for test */}
                <EmptyUser />
                <p className="bugo-h1">로그인</p>
              </div>
              <ChevronRight />
            </div>
          </Link>
          {sharedMenu}
        </div>
      );
    }
  };

  const navLeft = () => {
    if (pathName === '/flower-store' || title === '') {
      return (
        <Link to="/flower-store">
          <img src={DaemyungLogo} className="h-9"></img>
        </Link>
      );
    } else {
      return (
        <div className="flex items-center">
          <button
            onClick={() => {
              backUrl ? navigate(backUrl) : navigate(-1);
            }}
            className="center-box wh-10"
          >
            {/* TODO: Theme에 맞는 아이콘으로 변경. */}
            <Back />
          </button>
          <p className="bugo-h1">{title}</p>
        </div>
      );
    }
  };

  const navRight = () => {
    if (rightText === '') {
      return (
        <div className="center-box wh-10" onClick={() => setSideMenuOpen(true)}>
          {/* TODO: Theme에 맞는 아이콘으로 변경. */}
          <Menu />
        </div>
      );
    } else {
      return (
        <div>
          <p className="bugo-h2 theme-text-main">{rightText}</p>
        </div>
      );
    }
  };

  if (pathName.endsWith('login') || pathName.endsWith('error') || !navBarUse) {
    return null;
  } else {
    return (
      <>
        <div className="fixed z-20 flex h-14 w-full max-w-inherit items-center justify-between px-4 theme-bg-2">
          {navLeft()}
          {navRight()}
        </div>
        <SidePopup openId={openId}>{storeSideMenu(guestAuthenticated)}</SidePopup>
      </>
    );
  }
};
