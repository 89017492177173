const paymentMethodStatement = `결제방법에는 무통장 입금,실시간 계좌이체,카드결제, 휴대폰결제, 에스크로결제가 있으며 모든 결제 방법은 적립금과 함께 결제 가능합니다.

1. 간편결제(카카오페이) : 카카오페이를 통해 결제 하실 수 있습니다.

2.간편결제(네이버페이) - 네이버페이를 통해 결제 하실 수 있습니다.

3. 카드결제 : 카드결제 프로그램에 따라 결제를 진행하시면됩니다.

4. 휴대폰결제 : 주민번호와 휴대폰번호를 이용하여 승인된 인증번호로 결제하는 방법입니다.

이용요금은 추후 휴대폰요금 청구서에 합산됩니다.

5. 에스크로(실시간계좌이체)결제 : 무통장입금처럼 주문결제가 이루어지지만 실제 구매대금은 제 3자(에스크로PG사)에게 맡긴 뒤 물품 배송을 확인하고 판매자에게 지불하는 제도로써, 소비자보호를 위한 안전결제 수단 입니다.`;

const withoutAccountDepositStatement = `무통장입금시 주문서 작성후 안내되는 해당계좌번호로 물품구매금액을 입금해주시면 됩니다.

은행방문 (CD및 ATM기기포함), 폰뱅킹, 인터넷뱅킹, 모바일 뱅킹을 통하여 입금하는 방법입니다.`;

const withoutAccountDepositStatement2 = `결제방법을 무통장입금으로 선택 후 주문서를 작성하면 무통장입금 계좌번호가 안내됩니다.

주문 후에는 주문배송 페이지에서 해당 주문 건을 클릭하면 나오는 상세정보 페이지에서 계좌번호를 확인하실 수 있습니다.`;

const cancelStatement = `제품이 배송전인경우 고객센터를 통해 주문 변경 및 취소처리가 가능합니다.

고객님께서 주문을 하신 후, 입금전일 경우 온라인에서 주문취소가 가능합니다.

마이페이지>주문조회>주문취소

버튼을 누르시면 주문취소가 되십니다.

이미 발송된 상품은 발송이 끝나 버렸기 때문에 제품 변경 및 취소가 불가하오니 양해 부탁드립니다.

발송이 된 상품은 수령 후 반품하시는 왕복 택배비는 고객님께서 부담하셔야 합니다.

배송이 시작된 경우, 화환 및 조화의 경우는 단순환심으로 인한 반품이 불가능합니다.
`;

const refundStatement = `환불은 최초 고객님께서 결제를 하셨던 방법대로 환불됩니다.

카드 결제 시 카드사를 통해 결제가 취소 되며, 무통장 입금 시 고객님의 계좌로 다시 환불이 되는 방식입니다.

카드결제 후 현금으로 환불 받으실 수는 없습니다.`;

const returnStatement = `수령하신 후 7일 이내에 고객센터를 통해 사전 접수 합니다.

상품하자 및 배송중 파손 건에 대해서는 스마트나누미가 책임지고 환불 및 교환해 드리며, 고객님의 구매변심으로 인한 반품은 제품 특성상 어렵습니다.

반품및 교환으로 인한 회수는 저희 지정 택배를 이용하게 되며, 그외 택배로 고객님이 직접 보내실 경우 선불이 아닌 착불로 도착시 수취거부되어 반품 및 교환이 불가능 할수 있습니다.

꼭 저희 지정택배를 사용해 주시기 바랍니다.`;

const returnStatement2 = `보통 반품접수 후 2~3일 이내에 택배 기사님이 연락 후 회수하러 방문하십니다.

혹시라도 3일(주말 및 공휴일 제외)이 넘어도 오시지 않을 경우저희 고객센터로 연락주시면 바로 택배사 확인후 처리해 드리겠습니다.`;

const phraseChangeStatement = `문구 변경은 해당 상품 배송전까지 무료로 변경이 가능합니다.

하지만 배송이 시작된 후부터는 고객님의 단순 변심으로 인한 변경은 상황에 따라 추가 배송비를 지급하셔야합니다.

단 기존의 요청하셨던 문구와 다르게 배송이 간 경우, 확인되는 즉시 30분이내로 변경해드리겠습니다.
`;

export const getFAQStatements = () => {
  return {
    paymentMethodStatement,
    withoutAccountDepositStatement,
    withoutAccountDepositStatement2,
    cancelStatement,
    refundStatement,
    returnStatement,
    returnStatement2,
    phraseChangeStatement,
  };
};
