import { FC, useEffect, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import DateFilterSearcher from '@service/bugo/components/DateFilterSearcher';
import StatPageTableHeaderBox from '@service/bugo/components/StatPageTableHeaderBox';
import { ShopOrderForAgencyAdmin } from '@shared/api/shopOrder/shopOrder.interface';
import { User } from '@shared/api/user/user.interface';
import CheckboxFilter, { CheckBoxFilter } from '@shared/components/CheckboxFilter';
import { TextField } from '@shared/components/TextField';
import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { PaymentState } from '@shared/types';
import { getAgencyWorkerTeamName } from '@shared/utils/agencyWorkerUtils';
import { formatDate } from '@shared/utils/formatDate';
import { mainBgColorGenerator } from '@shared/utils/mainColorGenerator';
import { Table, TableColumnsType } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import FuzzySearch from 'fuzzy-search';
import _ from 'lodash';
import { CSVLink } from 'react-csv';

import WorkerDetailModalOpener from '../WorkerDetailModalOpener/WorkerDetailModalOpener';
interface Props {
  shopOrderList: ShopOrderForAgencyAdmin[];
  teamTypesFilter: CheckBoxFilter;
}

type ShopOrderTableData = {
  id: string;
  index: number;
  shopItemName: string;
  user: User;
  teamName: string;
  region: string;
  requestAt: Date | '';
  priceWhole: number;
  priceRetail: number;
};
const optionDetail = {
  month: '2-digit',
  day: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
} as any;

const getNetRriceWhole = (resultValue: ShopOrderForAgencyAdmin[]) =>
  resultValue.reduce((accu, prev) => {
    return accu + prev.orderDetail.shopItemEmbed.priceWhole;
  }, 0);

const getNetRriceAgencyIncome = (resultValue: ShopOrderForAgencyAdmin[]) =>
  resultValue.reduce((accu, prev) => {
    return accu + prev.orderDetail.shopItemEmbed.priceRebate;
  }, 0);

const getNetRriceIncome = (resultValue: ShopOrderForAgencyAdmin[]) =>
  resultValue.reduce((accu, prev) => {
    return accu + 10000;
  }, 0);

export const AgencyAdminShopOrderListTable: FC<Props> =
  function AgencyAdminShopOrderListTable({ shopOrderList, teamTypesFilter }: Props) {
    const customTheme = useRecoilValue(customThemeAtom);
    const [searchValue, setSearchValue] = useState<string>('');

    const [dateRange, setDateRange] = useState<[any | null, any | null]>([null, null]);

    //* 페이지네이션 안되있으면 이런애를 씌우면 일단 에러 안남
    const shopOrderPaid = useMemo(() => {
      return shopOrderList.filter((shopOrder) => {
        return (
          shopOrder.paymentDetail.status === PaymentState.CONFIRMED ||
          shopOrder.paymentDetail.status === PaymentState.PAID
        );
      });
    }, [shopOrderList]);

    const [dateFilteredShopOrderList, setDateFilteredShopOrderList] =
      useState<ShopOrderForAgencyAdmin[]>(shopOrderPaid);

    const onDateRangeChange: RangePickerProps['onChange'] = (dates) => {
      if (dates) {
        setDateRange(dates);
      } else {
        setDateRange([null, null]);
      }
    };

    // TeamType Filtering
    const [selectedTeamTypes, setSelectedTeamTypes] = useState(teamTypesFilter);

    const teamTypesFilteredFeventList = useMemo(() => {
      return dateFilteredShopOrderList.filter(
        (shopOrder) =>
          selectedTeamTypes[
            shopOrder.fevent?.user?.bugoAgencyWorkerDetail?.teamType ?? ''
          ],
      );
    }, [dateFilteredShopOrderList, selectedTeamTypes]);

    const resultValue = useMemo(() => {
      const searcher = new FuzzySearch(teamTypesFilteredFeventList, [
        'orderDetail.shopItemEmbed.name',
        'fevent.registerNumber',
        'fevent.user.info.name',
        'fevent.user.bugoAgencyWorkerDetail.teamName',
        'fevent.user.bugoAgencyWorkerDetail.teamType',
        'fevent.user.bugoAgencyWorkerDetail.region',
      ]);
      return searcher.search(searchValue);
    }, [teamTypesFilteredFeventList, searchValue]);

    //* dateRange 변경 적용
    useEffect(() => {
      const rangeFilteredList = shopOrderPaid.filter((shopOrder) => {
        if (dateRange[0] && dateRange[1]) {
          if (shopOrder.paymentDetail.purchasedAt) {
            const purchasedAt = new Date(shopOrder.paymentDetail.purchasedAt);
            if (
              dateRange[0].toDate() <= purchasedAt &&
              dateRange[1].toDate() >= purchasedAt
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
      setDateFilteredShopOrderList(rangeFilteredList);
    }, [dateRange, shopOrderPaid]);

    const columns: TableColumnsType<ShopOrderTableData> = [
      {
        title: 'index',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        render: (index) => {
          return <p className="font-medium">{index + 1}</p>;
        },
      },
      {
        title: '소속',
        dataIndex: 'teamName',
        key: 'teamName',
        align: 'center',
        render: (teamName) => {
          return <p className="font-medium">{teamName}</p>;
        },
      },
      {
        title: '소속지역',
        dataIndex: 'region',
        key: 'region',
        align: 'center',
        render: (region) => {
          return <p className="font-medium">{region}</p>;
        },
      },
      {
        title: '지도사(부고발송)',
        dataIndex: 'user',
        key: 'user',
        align: 'center',
        render: (user) => {
          return (
            <WorkerDetailModalOpener
              workerName={user.info.name}
              workerId={user._id}
              fontSize={14}
            />
          );
        },
      },
      {
        title: '상품명',
        dataIndex: 'shopItemName',
        key: 'name',
        align: 'center',
        render: (name) => {
          return <p className="font-medium">{name}</p>;
        },
      },
      {
        title: '주문일',
        dataIndex: 'requestAt',
        key: 'requestAt',
        align: 'center',

        render: (value: Date | '') => {
          return (
            <p>
              {value
                ? formatDate(value, {
                    contains: {
                      year: false,
                      month: true,
                      date: true,
                      day: true,
                      hours: false,
                      minutes: false,
                    },
                  })
                : ''}
            </p>
          );
        },
      },
      {
        title: '주문시각',
        dataIndex: 'requestAt',
        key: 'requestAt',
        align: 'center',

        render: (value: Date | '') => {
          return (
            <p>
              {value
                ? formatDate(value, {
                    contains: {
                      year: false,
                      month: false,
                      date: false,
                      day: false,
                      hours: true,
                      minutes: true,
                    },
                  })
                : ''}
            </p>
          );
        },
      },
      {
        title: '화환원가',
        dataIndex: 'priceWhole',
        key: 'priceWhole',
        align: 'right',
        render: (value: number) => {
          return <p className="text-gray-600">{value.toLocaleString()}원</p>;
        },
      },

      {
        title: '결제금액',
        dataIndex: 'priceRetail',
        key: 'priceRetail',
        align: 'right',
        render: (value: number) => {
          return <p className="text-gray-600">{value.toLocaleString()}원</p>;
        },
      },
    ];

    const tableData: ShopOrderTableData[] = useMemo(() => {
      return _.map(
        resultValue as ShopOrderForAgencyAdmin[] | null,
        (shopOrder, index): ShopOrderTableData => {
          return {
            index: index,
            id: shopOrder._id,
            shopItemName: shopOrder.orderDetail.shopItemEmbed.name,
            teamName: getAgencyWorkerTeamName(shopOrder.fevent.user),
            region: shopOrder.fevent.user.bugoAgencyWorkerDetail?.region ?? '',
            user: shopOrder.fevent.user,
            requestAt: shopOrder.paymentDetail.requestedAt ?? '',
            priceWhole: shopOrder.orderDetail.shopItemEmbed.priceWhole,
            priceRetail: shopOrder.orderDetail.shopItemEmbed.priceRetail,
          };
        },
      );
    }, [resultValue]);

    const csvTableData = tableData.map((item) => {
      return {
        소속: item.teamName,
        소속지역: item.region,
        지도사: item.user.info.name,
        상품명: item.shopItemName,
        주문일: item.requestAt
          ? formatDate(item.requestAt, {
              contains: {
                year: false,
                month: true,
                date: true,
                day: true,
                hours: false,
                minutes: false,
              },
            })
          : '',
        주문시각: item.requestAt
          ? formatDate(item.requestAt, {
              contains: {
                year: false,
                month: false,
                date: false,
                day: false,
                hours: true,
                minutes: true,
              },
            })
          : '',
        화환원가: item.priceWhole,
        결제금액: item.priceRetail,
      };
    });

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.currentTarget.value);
    };

    return (
      <div className="space-y-4">
        <div className="flex gap-8">
          <StatPageTableHeaderBox
            left="총 원가"
            right={getNetRriceWhole(resultValue).toLocaleString() + '원'}
          />
          <StatPageTableHeaderBox
            left="대명 이익금"
            right={getNetRriceAgencyIncome(resultValue).toLocaleString() + '원'}
          />
          <StatPageTableHeaderBox
            left="지도사"
            right={getNetRriceIncome(resultValue).toLocaleString() + '원'}
          />
          <StatPageTableHeaderBox
            left="화환 주문량"
            right={resultValue.length.toLocaleString() + '건'}
          />
        </div>
        <div className="rounded-lg bg-white px-4">
          <div className="flex w-full items-center justify-between py-4">
            <div className="flex items-center justify-start gap-2">
              <p className="font-medium text-gray-600">
                총{' '}
                <span className="font-bold text-black">
                  {resultValue.length.toLocaleString()}
                </span>
                건
              </p>
              <div className="flex items-center justify-start gap-2">
                <TextField
                  className="border-gray-500"
                  type={'search'}
                  onChange={onChangeHandler}
                  placeholder="검색 (지도사 정보, 회원번호, 상품명)"
                />
              </div>
            </div>
            <DateFilterSearcher
              dateRange={dateRange}
              onChange={onDateRangeChange}
              size="large"
            />
            {/* 소속 필터 */}
            <div className="flex items-center space-x-4 border text-sm theme-bg-1 theme-border-1">
              <div
                className={`center-box self-stretch bg-opacity-40 px-2 font-bold ${mainBgColorGenerator(
                  customTheme,
                )}`}
              >
                소속
              </div>
              <div className="flex flex-1 items-center justify-around space-x-4 py-1 pr-2">
                <CheckboxFilter
                  filter={selectedTeamTypes}
                  setFilter={(checked) => setSelectedTeamTypes(checked)}
                />
              </div>
            </div>
            <CSVLink
              filename={'Expense_Table.csv'}
              data={_.flatMapDeep([csvTableData])}
              className="center-box filled-gray-800 rounded-md px-4 py-2 font-bold"
            >
              <p className="text-white">엑셀 다운로드</p>
            </CSVLink>
          </div>
          <Table
            className="w-full"
            columns={columns}
            dataSource={_.flatMapDeep([tableData])}
            pagination={{
              position: ['bottomCenter'],
              defaultPageSize: 15,
              showSizeChanger: true,
              pageSizeOptions: [10, 15, 20, 50, 100],
            }}
            rowKey={'id'}
            bordered
            size={'small'}
          />
        </div>
      </div>
    );
  };
